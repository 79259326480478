import { createSlice } from "@reduxjs/toolkit";
import request from "../../services";
import Exported from "../../services/endpoints";
import { successToast } from "../../utils";

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState: {
    data: [],
    totalPages: 0,
  },
  reducers: {
    saveData: (state, action) => {
      state.data = action.payload;
    },
    saveTotalPage: (state, action) => {
      state.totalPages = action.payload;
    },
  },
});

export const getPromoCodes = (page, category, search) => async (dispatch) => {
  let searchQuery = search ? `&search=${search}` : ``;
  let categoryQuery = category ? `&category=${category}` : ``;

  request({
    url: `${Exported.Endpoints.GET_ALL_PROMO_CODE}?page=${page}${categoryQuery}${searchQuery}`,
    method: Exported.APIMethods.GET,
    isLoader: search == "",
  })
    .then((res) => {
      dispatch(saveData(res?.data?.data?.result));
      dispatch(saveTotalPage(res?.data?.data?.totalPages));
    })
    .catch((_err) => {});
};

export const deletePromoCodesById = (_id) => {
  return new Promise((resolve, reject) => {
    request({
      url: `${Exported.Endpoints.DELETE_PROMO_CODE_BY_ID}${_id}`,
      method: Exported.APIMethods.DELETE,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((_err) => {});
  });
};

export const createPromoCodeData = (data) => {
  return new Promise((resolve, _reject) => {
    request({
      url: `${Exported.Endpoints.CREATE_PROMO_CODE}`,
      method: Exported.APIMethods.POST,
      data,
    })
      .then((res) => {
        successToast(res?.data?.message);
        resolve(res?.data);
      })
      .catch((_err) => {});
  });
};

export const updatePromoCodeData = (data) => {
  return new Promise((resolve, _reject) => {
    request({
      url: `${Exported.Endpoints.UPDATE_PROMO_CODE}`,
      method: Exported.APIMethods.PUT,
      data,
    })
      .then((res) => {
        successToast(res?.data?.message);
        resolve(res?.data);
      })
      .catch((_err) => {});
  });
};

export const getPromoCodeById = (_id) => {
  return new Promise((resolve, _reject) => {
    request({
      url: `${Exported.Endpoints.GET_PROMO_CODE_BY_ID}${_id}`,
      method: Exported.APIMethods.GET,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((_err) => {});
  });
};

export const { saveData, saveTotalPage } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
