const Images = {
  logo: require("./images/logo.png"),
  loginBg: require("./images/loginBg.png"),
  login: require("./images/login.png"),
  profile_picture: require("./icons/header/profilePicture.png"),
  badges1: require("./images/badges1.png"),
  badges2: require("./images/badges2.png"),
  badges3: require("./images/badges3.png"),
  badges4: require("./images/badges4.png"),
  badges5: require("./images/badges5.png"),
  badges6: require("./images/badges6.png"),
  badges7: require("./images/badges7.png"),
  badges8: require("./images/badges8.png"),
  art1: require("./images/art1.png"),
  art2: require("./images/art2.png"),
  art3: require("./images/art3.png"),
  art4: require("./images/art4.png"),
  art5: require("./images/art5.png"),
  art6: require("./images/art6.png"),
  art7: require("./images/art7.png"),
  art8: require("./images/art8.png"),
  icon1: require("./images/icon1.png"),
  icon2: require("./images/icon2.png"),
  icon3: require("./images/icon3.png"),
  icon4: require("./images/icon4.png"),
  icon5: require("./images/icon5.png"),
  icon6: require("./images/icon6.png"),
  icon7: require("./images/icon7.png"),
  icon8: require("./images/icon8.png"),
  tutorial1: require("./images/tutorial1.png"),
  tutorial2: require("./images/tutorial2.png"),
  tutorial3: require("./images/tutorial3.png"),
  tutorial4: require("./images/tutorial4.png"),
  staff: require("./images/staff.png"),
  profile: require("./images/profile.png"),
  user_placeholder: require("./images/user_placeholder.png"),
  qr: require("./images/qr.jpg"),
};

const Icons = {
  badges: require("./icons/badges.png"),
  content: require("./icons/content.png"),
  dashboard: require("./icons/dashboard.png"),
  log_out: require("./icons/log_out.png"),
  payment: require("./icons/payment.png"),
  settings: require("./icons/settings.png"),
  link: require("./icons/link.png"),
  customer_care: require("./icons/customer_care.png"),
  staff: require("./icons/staff.png"),
  terms_policy: require("./icons/privacy_icon.png"),
  users: require("./icons/users.png"),
  email: require("./icons/login/email.png"),
  success: require("./icons/login/success.png"),
  password: require("./icons/login/password.png"),
  eye: require("./icons/login/eyeIcon.png"),
  emailError: require("./icons/login/emailError.png"),
  error: require("./icons/login/error.png"),
  bell: require("./icons/header/bell.png"),
  down: require("./icons/header/down-arrow.png"),
  up_trend: require("./icons/up_trend.png"),
  equal_line: require("./icons/equal-line.png"),
  down_trend: require("./icons/down-trend.png"),
  search: require("./icons/search.png"),
  action: require("./icons/actionIcon.png"),
  close_popup: require("./icons/popup_close.png"),
  left_icon: require("./icons/left_icon.png"),
  username: require("./icons/username.png"),
  calendar: require("./icons/calendar.png"),
  country: require("./icons/country.png"),
  edit: require("./icons/edit.png"),
  white_edit: require("./icons/white_edit.png"),
  feature_close: require("./icons/feature_close.png"),
  feature_success: require("./icons/feature_success.png"),
  delete: require("./icons/delete.png"),
  white_delete: require("./icons/white_delete.png"),
  privacy_edit: require("./icons/privacy_edit.png"),
  customer_ticket: require("./icons/customer_ticket.png"),
  notification_user: require("./icons/notification_user.png"),
  new_admin: require("./icons/new_admin.png"),
  pagination_left: require("./icons/pagination_left.png"),
  pagination_right: require("./icons/pagination_right.png"),
  tab_icon: require("./icons/tab_icon.png"),
  hide_icon: require("./icons/hide.png"),
  filter: require("./icons/filter.png"),
  date: require("./icons/date.png"),
  profile_edit: require("./icons/profile_edit.png"),
  downloadIcon: require("./icons/download.png"),
  notificationIcon: require("./icons/notificationIcon.png"),
  video: require("./icons/video.png"),
  mp3: require("./icons/mp3.png"),
  dropdown_indicator: require("./icons/dropdown_indicator.png"),
  close: require("./icons/close.png"),
  download_invoice: require("./icons/download_invoice.png"),
  calendar_sidebar: require("./icons/calendar_sidebar.png"),
  promo_code: require("./icons/promo_code.png"),
};

const Lottie = {
  loader: require("./lottie/loader.json"),
  page404: require("./lottie/404.json"),
  noData: require("./lottie/no_data.json"),
  forbidden: require("./lottie/forbidden.json"),
};

const ExportedData = {
  Images,
  Icons,
  Lottie,
};

export default ExportedData;
