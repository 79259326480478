import { combineReducers } from "@reduxjs/toolkit";
import appConfigsSlice from "./appConfigsSlice";
import authSlice from "./authSlice";
import badgesSlice from "./badgesSlice";
import customerCareSlice from "./customerCareSlice";
import dashboardSlice from "./dashboardSlice";
import deeplinkSlice from "./deeplinkSlice";
import dropdownSlice from "./dropdownSlice";
import notificationSlice from "./notificationSlice";
import paymentSlice from "./paymentSlice";
import plansSlice from "./plansSlice";
import privacyTermsSlice from "./privacyTermsSlice";
import promoCodeSlice from "./promoCodeSlice";
import selfCareSlice from "./selfCareSlice";
import staffSlice from "./staffSlice";
import subscriptionSlice from "./subscriptionSlice";
import tutorialSlice from "./tutorialSlice";
import userSlice from "./userSlice";

const reducers = combineReducers({
  auth: authSlice,
  notification: notificationSlice,
  staff: staffSlice,
  dropdown: dropdownSlice,
  privacyTerms: privacyTermsSlice,
  badges: badgesSlice,
  tutorials: tutorialSlice,
  selfCare: selfCareSlice,
  customerCare: customerCareSlice,
  dashboard: dashboardSlice,
  user: userSlice,
  plans: plansSlice,
  subscriptions: subscriptionSlice,
  payment: paymentSlice,
  appConfigs: appConfigsSlice,
  deeplinks: deeplinkSlice,
  promoCodes: promoCodeSlice,
});

export default reducers;
