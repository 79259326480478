import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NormalButton from "../../components/NormalButton";
import NormalTable from "../../components/NormalTable";
import DeleteDropDown from "../../components/deleteModal";
import InputFieldNoValidation from "../../components/inputFieldNoValidation";
import NoData from "../../components/noData";
import TooltipComponent from "../../components/tooltip";
import {
  deletePromoCodesById,
  getPromoCodes,
} from "../../redux/reducers/promoCodeSlice";
import "../users/styles.scss";
import "./styles.scss";

const PromoCodes = () => {
  let navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const headerDetails = [
    { label: "S.No" },
    { label: "Promo Code" },
    { label: "User Type" },
    { label: "Owner" },
    { label: "Start Date" },
    { label: "End Date" },
    { label: "Code Limit" },
    { label: "Discount (%)" },
    { label: "Total Free Days" },
    { label: "Action" },
  ];
  // const options = [
  //   { label: "Tasks", value: 1 },
  //   { label: "Premium", value: 2 },
  //   { label: "Free", value: 3 },
  // ];

  // const [categoryOptions, setCategoryOptions] = useState([
  //   { label: "Free Days", value: "free_days" },
  //   { label: "Price Discount", value: "price_discount" },
  // ]);

  const userCategories = [
    "All Users",
    "Free Users",
    "Paid Users",
    "New Users",
    "Existing Users",
    "iOS Users",
    "Android Users",
  ];

  const [page, setPage] = useState(1);
  const totalPage = useSelector((state) => state?.promoCodes?.totalPages || 0);

  const [search, setSearch] = useState("");
  const [category, setCatefory] = useState("");

  const data = useSelector((state) => state?.promoCodes?.data || []);

  const dispatch = useDispatch();

  useEffect(() => {
    getBagesData();
  }, [page, search, category]);

  useEffect(() => {
    if (data.length == 0 && page != 1) {
      window.location.reload();
    }
  }, [data]);

  const getBagesData = () => {
    dispatch(getPromoCodes(page, category, search));
  };

  const onClickEdit = (it) => {
    navigate("/main/add-promo-codes", {
      state: {
        data: it,
      },
    });
  };

  const onClickDelete = (it) => {
    setDeleteData(it);
    setModalOpen(true);
  };

  const onClickDeleteFromPopup = () => {
    setModalOpen(false);
    let { _id } = deleteData;

    deletePromoCodesById(_id)
      .then((_res) => {
        getBagesData();
      })
      .catch((_err) => { });
  };

  return (
    <div className="users-main-container">
      <div className="users-input-container ">
        <div className="search-container">
          <label></label>
          <InputFieldNoValidation
            leftIcon
            searchIcon
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {/* <div className="subscription-container me-3">
          <label className="subscription-label">Category</label>
          <NormalSelect
            options={categoryOptions}
            name="subscription"
            register={register}
            borderGreen
            disableButtons={true}
            onChange={(e) => {
              setCatefory(e?.value);
            }}
          />
        </div> */}
        <div className="subscription-container pt-4">
          <NormalButton
            label="CREATE NEW CODE"
            cancelBtn
            onClick={() => navigate("/main/add-promo-codes")}
          />
        </div>
      </div>
      <div className="users-table-container">
        <NormalTable
          headerDetails={headerDetails}
          pagination
          totalPages={totalPage}
          onPageChange={(page) => setPage(page)}
          page={page}
        >
          {data.length > 0 ? (
            <>
              {data.map((list, index) => {
                let {
                  _id,
                  zenitCode,
                  startDate,
                  endDate,
                  userType,
                  owner,
                  codeLimit,
                  discount,
                  totalFreeDays,
                } = list;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{zenitCode}</td>
                    <td>
                      {userType
                        .map((index) => userCategories[index - 1])
                        .join(", ")}
                    </td>
                    <td className="promo-code-category">{owner}</td>
                    <td>{moment(startDate).format("DD MMM YYYY")}</td>
                    <td>{moment(endDate).format("DD MMM YYYY")}</td>
                    <td>{codeLimit}</td>
                    <td>{discount ? discount : "NA"}</td>
                    <td>{totalFreeDays ? totalFreeDays : "NA"}</td>
                    <td align="center">
                      <TooltipComponent
                        onClickEdit={() => onClickEdit(list)}
                        onClickDelete={() => onClickDelete(list)}
                      />
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={11} className="text-center">
                <NoData />
              </td>
            </tr>
          )}
        </NormalTable>
      </div>
      <DeleteDropDown
        show={modalOpen}
        screen={"PromoCodes"}
        title={"Delete"}
        description={"Are you sure do you want to delete?"}
        onClickYes={onClickDeleteFromPopup}
        onHide={() => setModalOpen(false)}
      />
    </div>
  );
};

export default PromoCodes;
