import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import assets from "../../assets";
import CustomController from "../../components/customController";
import DatePicker from "../../components/DatePicker";
import NormalInput from "../../components/inputField";
import MultiSelect from "../../components/multiSelect";
import NormalButton from "../../components/NormalButton";
import {
  createPromoCodeData,
  getPromoCodeById,
  updatePromoCodeData,
} from "../../redux/reducers/promoCodeSlice";
import { dropDownValidation } from "../../utils";
import "../login/styles.scss";
import "./styles.scss";

const AddPromoCode = () => {
  let navigate = useNavigate();

  let location = useLocation();
  const { state } = location;

  const [promoCodeData, setPromoCodeData] = useState(state?.data);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const categoryOptions = [
    { label: "Free Days", value: "free_days" },
    { label: "Price Discount", value: "price_discount" },
  ];

  const userTypesData = [
    { label: "All Users", value: 1 },
    { label: "Free Users", value: 2 },
    { label: "Paid Users", value: 3 },
    { label: "New Users", value: 4 },
    { label: "Existing Users", value: 5 },
    { label: "iOS Users", value: 6 },
    { label: "Android Users", value: 7 },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [promoCodeLiveDate, setPromoCodeLiveDate] = useState(null);
  const [selectedUserTypes, setSelectedUserTypes] = useState([]);

  useEffect(() => {
    if (promoCodeData) {
      let { _id } = promoCodeData;

      getPromoCodeById(_id)
        .then((res) => {
          let {
            category,
            zenitCode,
            startDate,
            endDate,
            userType,
            owner,
            codeLimit,
            discount,
            promoCodeLiveDate,
            totalFreeDays,
          } = res?.data;

          setSelectedCategory(
            categoryOptions.filter((it) => it.value == category)[0]
          );
          setSelectedUserTypes(
            userType.map((index) => userTypesData[index - 1])
          );
          setPromoCodeData(res?.data);
          setStartDate(new Date(startDate));
          setEndDate(new Date(endDate));
          setPromoCodeLiveDate(new Date(promoCodeLiveDate));
          setValue("category", category);
          setValue("userType", "updated");
          setValue("zenitCode", zenitCode);
          setValue("startDate", moment(startDate).format("YYYY-MM-DD"));
          setValue("endDate", moment(endDate).format("YYYY-MM-DD"));
          setValue(
            "promoCodeLiveDate",
            moment(promoCodeLiveDate).format("YYYY-MM-DD")
          );
          setValue("owner", owner);
          setValue("codeLimit", codeLimit);
          setValue("discount", discount ? discount : "NA");
          setValue("totalFreeDays", totalFreeDays ? totalFreeDays : "NA");
        })
        .catch((_err) => { });
    }
  }, []);

  const onSubmit = (data) => {
    let { category, zenitCode, owner, codeLimit, discount, totalFreeDays } =
      data;
    let request = {
      zenitCode,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      promoCodeLiveDate: moment(promoCodeLiveDate).format("YYYY-MM-DD"),
      userType: selectedUserTypes.map((it) => it.value),
      owner,
      codeLimit,
      totalFreeDays,
    };

    if (promoCodeData) {
      request = {
        _id: promoCodeData?._id,
        ...request,
      };
      editPromoCode(request);
    } else {
      createPromoCode(request);
    }
  };

  const createPromoCode = (data) => {
    createPromoCodeData(data)
      .then((_res) => {
        navigate(-1);
      })
      .catch((_err) => { });
  };

  const editPromoCode = (data) => {
    updatePromoCodeData(data)
      .then((_res) => {
        navigate(-1);
      })
      .catch((_err) => { });
  };

  return (
    <>
      <form className="edit-user-container" onSubmit={handleSubmit(onSubmit)}>
        <div className="back-to-user-container">
          <img
            src={assets.Icons.left_icon}
            alt="left-icon"
            className="left-icon"
          />
          <span className="back-to-user" onClick={() => navigate(-1)}>
            Back to Promo Code List
          </span>
        </div>
        <div className="edit-user col-md-6 ">
          <p className="edit-user-details">
            {promoCodeData ? "Edit Promo Code" : "Add New Promo Code"}{" "}
          </p>
          <div className="col-md-12 d-flex">
            <div className=" col-md-6 ">
              {/* <div className="">
                <label className="login-input-label">Category</label>
                <CustomController
                  name="category"
                  control={control}
                  data={selectedCategory}
                  error={errors.category}
                  register={register(
                    "category",
                    dropDownValidation("Category")
                  )}
                  render={({ field: { onChange } }) => {
                    return (
                      <NormalSelect
                        options={categoryOptions}
                        borderGreen
                        value={selectedCategory}
                        error={errors.category}
                        onChange={(e) => {
                          setSelectedCategory(e);
                          console.log(e);

                          if (e?.value == "free_days") {
                            setValue("discount", "NA");
                            setValue("totalFreeDays", "");
                          } else {
                            setValue("totalFreeDays", "NA");
                            setValue("discount", "");
                          }
                          onChange(e?.value);
                        }}
                      />
                    );
                  }}
                />
              </div> */}
              <div className="">
                <label className="login-input-label">Code Text</label>
                <NormalInput
                  type={"text"}
                  register={register("zenitCode", {
                    required: "Code Text is required",
                  })}
                  error={errors.zenitCode}
                  messages={errors}
                  placeholder="Please Enter Code Name"
                />
              </div>
              <div className="">
                <label className="login-input-label">Start Date</label>
                <CustomController
                  name="startDate"
                  control={control}
                  data={startDate}
                  error={errors.startDate}
                  register={register(
                    "startDate",
                    dropDownValidation("Start Date")
                  )}
                  render={({ field: { onChange } }) => {
                    return (
                      <DatePicker
                        placeHolder={true}
                        format={"dd-MM-yyyy"}
                        placeholderText="Select from Start Date"
                        value={startDate ?? null}
                        error={errors.startDate}
                        onChange={(e) => {
                          setStartDate(e);
                          onChange("startDate");
                        }}
                        messages={errors}
                      />
                    );
                  }}
                />
              </div>
              <div className="">
                <label className="login-input-label">Code Limit</label>
                <NormalInput
                  type={"number"}
                  register={register("codeLimit", {
                    required: "Code Limit is required",
                  })}
                  error={errors.zenitCode}
                  messages={errors}
                  placeholder="Please Enter Code Limit"
                />
              </div>
              <div className="">
                <label className="login-input-label">User Type</label>
                <CustomController
                  name="userType"
                  control={control}
                  data={selectedUserTypes}
                  error={errors.userType}
                  register={register(
                    "userType",
                    dropDownValidation("User Type")
                  )}
                  render={({ field: { onChange } }) => {
                    return (
                      <MultiSelect
                        options={userTypesData}
                        borderGreen
                        value={selectedUserTypes}
                        error={errors.userType}
                        onChange={(e) => {
                          console.log(e);
                          setSelectedUserTypes(e);
                          if (e?.length > 0) {
                            onChange("user_type");
                          } else {
                            onChange(null);
                          }
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className=" col-md-6 ms-3">
              {/* <div className="">
                <label className="login-input-label">Discount (%)</label>
                <NormalInput
                  type={"text"}
                  disabled={selectedCategory?.value == "free_days"}
                  register={register("discount", {
                    required:
                      selectedCategory?.value === "price_discount"
                        ? "Discount is required"
                        : false,
                  })}
                  error={errors.discount}
                  messages={errors}
                  placeholder="Please Enter Discount"
                />
              </div> */}
              <div className="">
                <label className="login-input-label">
                  Total Free Premium Days
                </label>
                <NormalInput
                  type={"number"}
                  name={"totalFreeDays"}
                  register={register("totalFreeDays", {
                    required: "Free Days is required",
                  })}
                  error={errors.totalFreeDays}
                  messages={errors}
                  placeholder="Please Enter Free Days"
                />
              </div>
              <div className="">
                <label className="login-input-label">End Date</label>
                <CustomController
                  name="endDate"
                  control={control}
                  data={endDate}
                  error={errors.endDate}
                  register={register("endDate", dropDownValidation("End Date"))}
                  render={({ field: { onChange } }) => {
                    return (
                      <DatePicker
                        placeHolder={true}
                        format={"dd-MM-yyyy"}
                        placeholderText="Select from End Date"
                        value={endDate ?? null}
                        error={errors.endDate}
                        onChange={(e) => {
                          setEndDate(e);
                          onChange("endDate");
                        }}
                        messages={errors}
                      />
                    );
                  }}
                />
              </div>
              <div className="">
                <label className="login-input-label">Owner</label>
                <NormalInput
                  type={"text"}
                  register={register("owner", {
                    required: "Owner is required",
                  })}
                  error={errors.owner}
                  messages={errors}
                  placeholder="Please Enter Owner"
                />
              </div>
              <div className="refund-form-containers ">
                <label className="login-input-label">
                  Promo code Live Date
                </label>
                <CustomController
                  name="promoCodeLiveDate"
                  control={control}
                  data={promoCodeLiveDate}
                  error={errors.promoCodeLiveDate}
                  register={register(
                    "promoCodeLiveDate",
                    dropDownValidation("Promo code live Date")
                  )}
                  render={({ field: { onChange } }) => {
                    return (
                      <DatePicker
                        placeHolder={true}
                        format={"dd-MM-yyyy"}
                        placeholderText="Select from Start Date"
                        value={promoCodeLiveDate ?? null}
                        error={errors.promoCodeLiveDate}
                        onChange={(e) => {
                          setPromoCodeLiveDate(e);
                          onChange("promoCodeLiveDate");
                        }}
                        messages={errors}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="edit-user col-md-6 mt-3">
          <div className=" d-flex mt-3">
            <div className="edit-cancel-btn">
              <NormalButton
                label=" CANCEL"
                cancelBtn
                onClick={() => navigate("/main/staff")}
              />
            </div>
            <div className="edit-update-btn">
              <NormalButton
                label={promoCodeData ? `Edit` : `${"ADD"}`}
                mainBg
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddPromoCode;
