const Endpoints = {
  LOGIN: "/auth/users/signIn",
  FORGOT_PASSWORD: "/auth/users/forgotPassword",
  VERIFY_OTP: "/auth/users/verifyOtp",
  RESET_PASSWORD: "/auth/users/resetPassword",
  REFRESH_TOKEN: "/auth/refreshToken",
  RESET_PASSWORD: "/auth/users/resetPassword",
  UPDATE_PROFILE: "/staff/update-profile",
  STAFF_BY_ID: "/staff/get?_id=",

  //notification
  GET_ALL_NOTIFICATION: "/notifications/getAll",

  //staff
  GET_ALL_STAFF: "/staff/getAll",
  GET_STAFF_BY_ID: "/staff/get?_id=",
  DELETE_STAFF_BY_ID: "/staff/delete?_id=",
  GENERATE_EMP_ID: "/staff/generate-emp-id",
  CREATE_STAFF: "/staff/create",
  UPDATE_STAFF: "/staff/update",
  GENERATE_STAFF_ID: "/staff/generate-emp-id",

  //dropdown
  GET_DROP_DOWN_BY_CATEGORY: "/dropDown/getAllDropDownByCategory?category=",
  CREATE_DROP_DOWN: "/dropDown/create",
  UPDATE_DROP_DOWN: "/dropDown/updateById",
  DELETE_DROP_DOWN: "/dropDown/deleteById?_id=",

  //privacy and terms
  GET_PRIVACY_TERMS: "/termsAndPrivacy/getAll",
  CREATE_PRIVACY_TERMS: "/termsAndPrivacy/create",
  UPDATE_PRIVACY_TERMS: "/termsAndPrivacy/update",

  //badges
  CREATE_BADGES: "/badges/create",
  GET_ALL_BADGES: "/badges/getAll",
  GET_BADGE_BY_ID: "/badges/get?_id=",
  DELETE_BADEGE_BY_ID: "/badges/delete?_id=",
  UPDATE_BADGE: "/badges/update",

  //tutorials
  CREATE_TUTORIAL: "/tutorials/create",
  GET_ALL_TUTORIALS: "/tutorials/admin/get-all",
  GET_TUTORIAL_BY_ID: "/tutorials/get?_id=",
  DELETE_TUTORIAL_BY_ID: "/tutorials/delete?_id=",
  UPDATE_TUTORIAL: "/tutorials/update",
  GENERATE_TUTORIAL_ID: "/tutorials/generate-content-id",

  //selfcare
  CREATE_SELF_CARE: "/selfCare/create",
  GET_ALL_SELF_CARE: "/selfCare/getAll",
  GET_SELF_CARE_BY_ID: "/selfCare/get?_id=",
  DELETE_SELF_CARE_BY_ID: "/selfCare/delete?_id=",
  UPDATE_SELF_CARE: "/selfCare/update",
  GENERATE_SELF_CARE_ID: "/selfCare/generate-self-care-id",
  GENERATE_QR_SELF_CARE_ID: "/selfCare/get-qr-code-by-id",
  GET_ALL_RELATED_TRACKS_BY_CATEGORY:
    "/selfCare/get-related-tracks-by-category?category=",

  //plans
  GET_PLANS_DROP_DOWN: "/plans/get-subscription-plan-drop-down",
  GET_ALL_PLANS: "/plans/getAll",
  GET_ALL_PLANS_FEATURE: "/planFeature/getAll",
  CREATE_PLAN: "/plans/create",
  UPDATE_PLAN: "/plans/update",
  DELETE_PLAN: "/plans/delete?_id=",
  GET_PLAN_BY_ID: "/plans/get?_id=",

  //customer care
  GET_ALL_ADMIN_TICKETS: "/customerCare/admin/getAllTickets",
  UPDATE_TICKETS: "/customerCare/updateTicket",
  GET_TICKET_BY_ID: "/customerCare/getTicketById?_id=",

  //dashboard
  GET_USERS_COUNT: "/dashboard/users",
  GET_REVENUE: "/dashboard/revenue?type=",
  GET_FEATURES_COUNT: "/dashboard/features?type=",
  GET_USER_DEMOGRAPHY: "/dashboard/users-demography?type=",
  GET_CUSTOMER_CARE_COUNTS: "/dashboard/customer-care?type=",

  //user management
  GET_ALL_USERS: "/userManagement/getAll",
  DELETE_USER_BY_ID: "/userManagement/delete",
  GET_USER_BY_ID: "/userManagement/get?_id=",
  UPDATE_USER: "/userManagement/update",
  UNIVERSAL_FILTER_GET: "/userManagement/universal-filter-data",
  APPLY_UNIVERSAL_FILTER: "/userManagement/apply-universal-filter",
  APPLY_UNIVERSAL_PROMO: "/userManagement/apply-universal-promo",

  //other dropdowns
  COUNTRY_DROP_DOWN: "/countries/get-country-drop-down",

  //subscription
  RECENT_SUBSCRIPTION: "/payments/recent-subscription",
  GET_ALL_SUBSCRIPTION: "/payments/get-all-subscription",
  DELETE_SUBSCRIPTION: "/payments/delete",
  DOWNLOAD_INVOICE: "/payments/download-invoice",

  //dashboard
  GET_PAYMENT_COUNT: "/payments/payment-counts",
  GET_SUBSCRIPTION_GROWTH: "/payments/subscription-growth?type=",
  GET_SUBSCRIPTION_BY_LOCATION: "/payments/subscription-by-location?type=",
  UPDATE_REFUND_DETAILS: "/payments/update-refund-details",

  UPDATE_COMM_STATUS: "/customerCare/update-comm-status",

  //user notifications
  GET_ALL_USER_NOTIFICATION: "/userNotification/getAll",
  CREATE_USER_NOTIFICATION: "/userNotification/create",

  //app configs
  GET_ALL_APP_CONFIGS: "/applicationConfigs/getAll",
  UPDATE_APP_CONFIG: "/applicationConfigs/update",

  //deleted users
  GET_ALL_DELETED_USERS: "/auth/users/get-all-deleted-user",
  RE_ACTIVATE_USER: "/auth/users/re-activate-user",

  GET_ALL_DEEPLINKS: "/deeplinks/getAll",
  CREATE_DEEPLINKS: "/deeplinks/create",
  DELETE_DEEPLINKS: "/deeplinks/delete?_id=",
  GET_TRACKS_BY_ID: "/selfCare/get-all-self-care-by-category?category=",

  //promo codes
  CREATE_PROMO_CODE: "/promoCodes/create",
  GET_ALL_PROMO_CODE: "/promoCodes/getAll",
  GET_PROMO_CODE_BY_ID: "/promoCodes/get?_id=",
  DELETE_PROMO_CODE_BY_ID: "/promoCodes/delete?_id=",
  UPDATE_PROMO_CODE: "/promoCodes/update",
};

const APIMethods = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

const Exported = {
  Endpoints,
  APIMethods,
};

export default Exported;
